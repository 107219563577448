<template>
	<label class="label" :class="computedClasses">
		<slot />
	</label>
</template>

<script>
export default {
	name: 'CLabel',
	props: {
		faded: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		bold: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedClasses() {
			return {
				label__faded: this.faded,
				label__small: this.small,
				label__bold: this.bold,
				label__error: this.error,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/variables';

.label {
	text-align: start;
	font-weight: normal;

	&__small {
		font-size: $label;
	}

	&__faded {
		color: rgba(255, 255, 255, 0.6);
	}

	&__bold {
		font-weight: bold;
	}

	&__error {
		color: $torch-red;
		margin: 0.2rem 0;
	}
}
</style>
